$(document).ready(function (event)
{
	// Clear all filters
	$('#clear_filters_btn').on('click', function(event)
	{
		$('#filters .filter_option.selected').removeClass('selected');

		$('#filters .tool_filter_option').each(function(){
			$(this).find('input').prop('checked', false);
		});
		$('.filterable_thumb').show();
	});

	// Toggle a filter
	$('#filters .filter_option').on('click', function(event)
	{
		// Decide whether we're turning it off or on
		if ($(this).hasClass('selected'))
		{
			$(this).removeClass('selected');
		}
		else
		{
			$(this).addClass('selected');
		}

		// Get selected filters
		var selected_filters = [];
		$('#filters .filter_option.selected').each(function(index)
		{
			selected_filters.push($(this).attr('data-filter-id'));
		});

		if (selected_filters.length > 0)
		{
			// Loop related entry thumbs, deciding whether to hide or show
			$('.filterable_thumb').each(function(index)
			{
				var all = true;
				var any = false;
				var entry_filter_ids = $(this).attr('data-filter-ids').split(';');

				for (var i = 0; i < selected_filters.length; i++)
				{
					if (entry_filter_ids.indexOf(selected_filters[i]) === -1)
					{
						all = false;
					}
					else
					{
						any = true;
					}
				}

				// If ALL filters are present
				if (all && any)
				{
					$(this).addClass('visible');
				}
				else
				{
					$(this).removeClass('visible');
				}
			});
		}
		else
		{
			$('.filterable_thumb').addClass('visible');
		}

		$('.filterable_thumb').removeClass('col_1 col_2 col_3 last');
		$('.filterable_thumb.visible').each(function(index)
		{
			var i = index + 1;
			if (i % 3 == 1) $(this).addClass('col_1');
			else if (i % 3 == 2) $(this).addClass('col_2');
			else if (i % 3 == 0) $(this).addClass('col_3');
		});

		$('.filterable_thumb.visible').last().addClass('last');
	});

	// Toggle a tool filter
	$('#filters .tool_filter_option').on('click', function(event)
	{
		// Decide whether we're turning it off or on
		var $input = $(this).find('input')
		if ($input.is(':checked'))
		{
			$input.find('input').prop('checked', false);
		}
		else
		{
			$input.find('input').prop('checked', true);
		}

		// Get selected filters
		var selected_filters = [];
		$('#filters .tool_filter_option input:checked').each(function(index)
		{
			selected_filters.push($(this).closest('.tool_filter_option').attr('data-filter-id'));
		});

		if (selected_filters.length > 0)
		{
			// Loop related entry thumbs, deciding whether to hide or show
			$('.tool').each(function(index)
			{
				var all = true;
				var any = false;
				var entry_filter_ids = $(this).attr('data-filter-ids').split(';');

				for (var i = 0; i < selected_filters.length; i++)
				{
					if (entry_filter_ids.indexOf(selected_filters[i]) === -1)
					{
						all = false;
					}
					else
					{
						any = true;
					}
				}

				// If ALL filters are present
				if (all && any)
				{
					$(this).closest('.tools-grid-container').show();
				}
				else
				{
					$(this).closest('.tools-grid-container').hide();
				}
			});
		}
		else
		{
			$('.tools-grid-container').show();
		}
	});
});
