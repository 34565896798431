$(document).ready(function (event) {
    $('.nav-item span.nav-bottom-row__left-link').on('click', function (event) {
        event.stopImmediatePropagation();

        var $nav_item = $(this).closest('.nav-item');
        if ($nav_item.hasClass('submenu-open')) {
            $nav_item.removeClass('submenu-open').find('.nav-submenu-wrapper').slideUp(250);
            $nav_item.find('.down-arrow__nav').removeClass('submenu-open-icon');
            $nav_item.find('.nav-bottom-row__left-link').css("color", "#fff");
        } else {
            // Close any open navigation sections first
            if ($('.nav-item.submenu-open').length) {
                $('.nav-item.submenu-open').find('.down-arrow__nav').removeClass('submenu-open-icon');
                $('.nav-item.submenu-open').find('.nav-bottom-row__left-link').css("color", "#fff");
                $('.nav-item.submenu-open').removeClass('submenu-open').find('.nav-submenu-wrapper').slideUp(250, function () {
                    // Now open this one
                    $nav_item.addClass('submenu-open').find('.nav-submenu-wrapper').slideDown(250);
                    $nav_item.find('.down-arrow__nav').addClass('submenu-open-icon');
                    $nav_item.find('.nav-bottom-row__left-link').css("color", "#dfdfcf");
                });
            } else {
                $nav_item.addClass('submenu-open').find('.nav-submenu-wrapper').slideDown(250);
                $nav_item.find('.down-arrow__nav').addClass('submenu-open-icon');
                $nav_item.find('.nav-bottom-row__left-link').css("color", "#dfdfcf");
            }
        }

        return false;
    });

    // MyBrake subnav
    $('.mybrake-link').on('click', function (event) {
        $('#mybrake-subnav').slideToggle(250);
        $('#mybrake-subnav-mobile').toggleClass('closed');
    });

    // Mobile menu
    var window_width = $(window).width()
    function reset_nav()
    {
        window_width = $(window).width()
        if (window_width > 768)
        {
            $('#close_menu_btn, #menu_btn').hide();
            $('.nav-mobile-container').slideDown(0);
        }
        else
        {
            $('.nav-mobile-container').slideUp(0);
            $('#menu_btn').show();
        }
    }
    reset_nav();

    $(window).on('resize', function(){
        if ($(window).width() !== window_width)
        {
            reset_nav();
        }
    });

    $('#menu_btn').on('click', function (event) {
        $('.nav-mobile-container').slideDown(250);
        $('#close_menu_btn').show();
        $('#menu_btn').hide();
    });

    $('#close_menu_btn').on('click', function (event) {
        $('.nav-mobile-container').slideUp(250);
        $('#close_menu_btn').hide();
        $('#menu_btn').show();
    });

});