// JS

// Include JS files here
require('./js/misc');
require('./js/cookie');
require('./js/accordion');
require('./js/dashboard');
require('./js/nav');
require('./js/tools');
require('./js/filters');
require('./js/donation');

// Root CSS file
require('./scss/index.scss');

// Enable HMR for jQuery
if (module.hot) {
	// Take hot modules
	module.hot.accept();
	// Turn off any events in case these have affected the DOM
	module.hot.dispose(function () {
		$('*').off();
	});
}
