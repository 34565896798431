// Functionality corresponding to /templates/user/dashboard.twig

$(document).ready(function () {

	// Show profile form
	$('#edit_user_profile_btn').on('click', function () {
		$('#user_profile').hide();
		$('#user_profile_form').show();
	});

	// Hide profile form
	$('#user_profile_form .dashboard__cancel-btn').on('click', function () {
		$('#user_profile').show();
		$('#user_profile_form').hide();
	});

	// Close message box
	$('.close_message_box_btn').on('click', function () {
		$('.message_box').closest('.dashboard__message-container').slideUp(500);
	});

	// Show user interest areas form
	$('#edit_user_interest_areas_btn').on('click', function () {
		$('#user_interest_areas').hide();
		$('#user_interest_areas_form').show();
	});

	// Hide user interest areas form
	$('#user_interest_areas_form .dashboard__cancel-btn').on('click', function () {
		$('#user_interest_areas').show();
		$('#user_interest_areas_form').hide();
	});

	// Show preview of user picture
	$("#user_photo_field").change(function (event) {
		var input = $(this)[0];
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (event) {
				$('#user_photo_preview').removeClass('bt-sub-heading user-profile__name');
				$('#user_photo_preview').addClass('user-profile__img-image');
				$('#user_photo_preview').text('');
				$('#user_photo_preview').css('background-image', `url(${event.target.result})`);
			};
			reader.readAsDataURL(input.files[0]);
		}
		event.stopImmediatePropagation();
	});

	// Toggle view of goal progress
	$('.goal_toggle_progress_button').on('click', function (event) {
		$(this).toggleClass('arrow-rotate');
		$(this).closest('.goal').find('.goal_progress').slideToggle(250);
	});

	// Add a new goal
	$('#add_goal_btn').on('click', function (event) {
		var $add_goal_form = $('#goal_form_template form').clone(true, true);
		$add_goal_form.appendTo($('#user_goals'));
		$add_goal_form.wrap('<div class="goal_wrapper"></div>');
		$add_goal_form.show();
	});

	// Edit existing goal
	$('.edit_goal_btn').on('click', function (event) {
		var $goal_wrapper = $(this).closest('.goal_wrapper');
		$goal_wrapper.find('.goal').hide();
		$goal_wrapper.find('form.goal_form').show();
	});

	// Delete goal (but confirm first with the user)
	$('.delete_goal_btn').on('click', function (event) {
		var confirmed = confirm("Are you sure you want to delete this goal? This cannot be undone.");
		if (confirmed === true) {
			var $form = $(this).closest('form').submit();
		}
	});

	// Cancel editing goal (but confirm first with the user)
	$('.goal_form .dashboard__cancel-btn').on('click', function (event) {
		var confirmed = confirm("Are you sure you want to cancel editing this goal? Your changes will not be saved.");
		if (confirmed === true) {
			var $goal_wrapper = $(this).closest('.goal_wrapper');
			$goal_wrapper.find('.goal').show();
			$goal_wrapper.find('form.goal_form').hide();
		}
	});

	// Add a step to a goal
	$('.add_goal_step_btn').on('click', function (event) {
		var $steps_container = $(this).closest('form.goal_form').find('.steps');
		var step_number = $steps_container.find('.step').length + 1;

		// Set the names of the input fields so that they can be identified properly in the backend
		var $new_step = $('#goal_form_step_template .step').clone(true, true);
		$new_step.find('input, textarea').each(function (index) {
			var input_name = $(this).attr('name');
			input_name = input_name.replace(/steps\[[^\]]+\]/, 'steps[new' + step_number + ']');
			$(this).attr('name', input_name);
		});

		$new_step.appendTo($steps_container);
		$new_step.show();
	});

	// Remove a step from a goal
	$('.delete_goal_step_btn').on('click', function (event) {
		$(this).closest('.step').remove();
	});

	// Update goal description when interest area has been changed
	$('.goal_form input[name=interest_area]').on('change', function (event) {
		var interest_area = $(this).val();
		var $goal_form = $(this).closest('.goal_form');

		$('.interest-area__select').show();

		$goal_form.find('label.interest_area').removeClass('interest-area__item-chosen');
		$(this).closest('label.interest_area').addClass('interest-area__item-chosen');

		$goal_form.find('select[name=goal_description]')
			.hide()
			.prop('required', false)
			.prop('disabled', true);

		$goal_form.find('select[name=goal_description][data-interest-area=' + interest_area + ']')
			.prop('required', true)
			.prop('disabled', false)
			.show();
	});

	// Hide 'mark as resolved' checkbox if the step is an achievement
	$('.step_type_field_group input').on('change', function (event) {
		var $step = $(this).closest('.step');
		$step.find('.step_type_field_group label').removeClass('selected');
		$(this).closest('label').addClass('selected');
		var $mark_as_resolved_field = $step.find('.step_resolved_field_group');
		if ($(this).val() == 'achievement') {
			$mark_as_resolved_field.prop('disabled', true).hide();
			$step.addClass('achievement');
		} else {
			$mark_as_resolved_field.prop('disabled', false).show();
			$step.removeClass('achievement');
		}
	});

	// Mark a step as 'resolved'
	$('.step_resolved_field_group input').on('change', function (event) {
		if ($(this).is(':checked')){
			$(this).closest('.step').addClass('resolved');
		} else {
			$(this).closest('.step').removeClass('resolved');
		}
	});

	// Show characters remaining in step description
	$('.step_description_field').on('keyup', function (event) {
		var maxlength = parseInt($(this).attr('maxlength'));
		if (maxlength > 0) {
			var characters_remaining = maxlength - $(this).val().length;
			var $characters_remaining_text = $('.step_description_characters_remaining');
			$(this).closest('.step').find('.step_description_characters_remaining').text(characters_remaining);
		}
	});

});
