jQuery(document).ready(function($)
{
	$('#donate_widget form').on('submit', function(event)
	{
		event.preventDefault();
		var $form = $(this);
		$form.find('.donation_type input').prop('disabled', true);

		$form.off('submit').submit();

		return false;
	});

	$('#donate_widget .donation_type input').on('change', function(event)
	{
		var type = $(this).val();
		var $form = $(this).closest('form');
		var $donation_widget_wrapper = $form.closest('#donate_widget_wrapper');
		var $amount_by_type = $form.find('.amount-by-type');

		$('#donation_options_monthly, #donation_options_single').hide();
		$('#donation_options_monthly input, #donation_options_single input').prop('checked', false);

		if (type == 'monthly')
		{
			$('#donation_options_monthly').show();
			$('#donation_options_monthly input').first().trigger('click');

			$form.attr('action', '/donation-form-monthly');

			var monthly_bg_image = $form.closest('#donate_widget_wrapper').attr('data-monthly-bg');
			if (monthly_bg_image)
			{
				$donation_widget_wrapper.css('background-image', `url('${monthly_bg_image}')`);
			}
			$amount_by_type.text('monthly donation');
		}
		else if (type == 'single')
		{
			$('#donation_options_single').show();
			$('#donation_options_single input').first().trigger('click');

			$form.attr('action', '/donation-form-single');

			var single_bg_image = $form.closest('#donate_widget_wrapper').attr('data-single-bg');
			if (single_bg_image)
			{
				$donation_widget_wrapper.css('background-image', `url('${single_bg_image}')`);
			}
			$amount_by_type.text('one-off donation');
		}
	});

	$('#donate_widget #donation_other_amount_field').on('keyup', function(event)
	{
		var other_amount = $(this).val();
		var other_amount_numeric = Number(other_amount);
		var $form = $(this).closest('form');

		if (isNaN(other_amount_numeric) || other_amount !== "" && other_amount_numeric < 1)
		{
			$form.find('input[type=submit]').prop('disabled', true);
		}
		else
		{
			$form.find('input[type=submit]').prop('disabled', false);
		}
	});

});
