$(document).ready(function () {

	// Hide accordions by default
	$('.accordion_content').hide();

	// Event listener for clicking an accordion title
	$('.accordion_title').on('click', function () {

		var $accordion_title = $(this);
		var $accordion = $(this).closest('.accordion-container');

		if ($accordion_title.hasClass('accordion-open'))
		{
			$accordion.find('.accordion_content').slideUp(250);
			$accordion_title.find('span').removeClass('arrow-rotate');
			$accordion_title.removeClass('accordion-open');
		}
		else
		{
			$accordion.find('.accordion_content').slideDown(250);
			$accordion_title.find('span').addClass('arrow-rotate');
			$accordion_title.addClass('accordion-open');
		}

		return false;
	});
});
