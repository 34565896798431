// On window load, replace any placeholder images with the actual images we want, referenced in the data-src attribute
// This is a neat way of deferring image loads til we have loaded the whole page
function lazy_load()
{
	var $deferred_images = document.querySelectorAll('img[data-src]');
	for (var i = 0; i < $deferred_images.length; i++)
	{
		var image_src = $deferred_images[i].getAttribute('data-src');
		if (image_src !== "")
		{
			$deferred_images[i].setAttribute('src', image_src);
		}
	}
}
window.onload = lazy_load;

$(document).ready(function(event)
{
	// Toggle display of phone number field
	$('input#agree_phone_field').on('change', function(event)
	{
		var $phone_field = $('#phone_field');
		var $field_group = $phone_field.closest('.field_group');

		if ($(this).is(':checked'))
		{
			$phone_field.prop('disabled', false);
			$phone_field.prop('required', true);
			$field_group.show();
		}
		else
		{
			$phone_field.prop('disabled', true);
			$phone_field.prop('required', false);
			$field_group.hide();
		}
	});

	// Toggle display of organisation and job_title fields
	function toggle_organisation_and_job_title_fields()
	{
		var values = [];
		$('#i_am_a_field_wrapper input:checked').each(function(index) {
			values.push($(this).val());
		});

		var $organisation_and_job_title_fields = $('#organisation_and_job_title_fields');

		if (values.indexOf('professional_prevent_crashes') !== -1 || values.indexOf('professional_help_victims') !== -1)
		{
			$organisation_and_job_title_fields.show();
			$organisation_and_job_title_fields.find('input')
				.prop('disabled', false)
				.prop('required', true);
		}
		else
		{
			$organisation_and_job_title_fields.hide();
			$organisation_and_job_title_fields.find('input')
				.prop('disabled', true)
				.prop('required', false)
				.val("");
		}
	}
	toggle_organisation_and_job_title_fields();

	$('#i_am_a_field_wrapper input').on('change', function(event)
	{
		toggle_organisation_and_job_title_fields();
	});

});
