$(document).ready(function()
{
	// Toggle whether we're viewing 'all tools' or 'my tools' on tool listing page
	$('#tools_page_list_options input').on('change', function(event)
	{
		var value = $(this).val();
		if (value == 'saved_tools')
		{
			$('.tool').hide();
			$('.tool.saved_tool').show();
		}
		else
		{
			$('.tool').show();
		}
	});

	// Submit 'save tool' form by AJAX
	$(document).on('submit', '.save_tool_form', function(event)
	{
		event.preventDefault();

		var $form = $(this);

		$.ajax({
			method: 'POST',
			data: $form.serialize(),
			contentType: 'application/x-www-form-urlencoded',
			cache: false,
			headers: {
				'Accept': 'application/json',
			},
			beforeSend: function(){
				$form.prop('disabled', true);
			},
			complete: function(){
				$form.prop('disabled', false);
			},
			success: function(data){
				if (data.success)
				{
					if (data.remove == true)
					{
						$form.find('button').text('Favourite');
						$form.closest('.tool').removeClass('saved_tool');
					}
					else
					{
						$form.find('button').text('Unfavourite');
						$form.closest('.tool').addClass('saved_tool');
					}
				}
			},
			error: function (xhr, ajaxOptions, thrownError) {
				alert("There was an error. Please try again.");
				console.log("ERROR: " + xhr.status + " " + thrownError);
			}
		});

		return false;
	});

	$('.anchorSpan').click(function() {
		var link = $(this).data('link');
		copyToClipboard(link);
	});

	function copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(element).select();
    document.execCommand("copy");
    $temp.remove();
	}

	$('.hall-of-fame-grid img').click(function() {
		$('.hall-of-fame-grid div').removeClass('active');
		$(this).parent().addClass('active');

		var i = $(this).parent().index();

		$('.hall-of-fame-active div').removeClass('active');
		$('.hall-of-fame-active div').eq(i).addClass('active');

		$('html,body').animate({
   		scrollTop: $('.hall-of-fame-active').offset().top - 20
		}, 500, 'linear');
	});

	$('.hall-of-fame-btt').click(function() {
		$('html,body').animate({
   		scrollTop: $('.hall-of-fame').offset().top - 20
		}, 500, 'linear');
	})
});
