if (typeof(Storage) !== "undefined")
{
    document.addEventListener('click', function(event)
    {
        if (event.target.matches('.cookie-banner button'))
        {
            localStorage.setItem('cookie_accept', 1);
            document.querySelector('.cookie-banner').classList.remove('cookie-banner__visible');
        }
    });

    if ( localStorage.cookie_accept ? localStorage.cookie_accept == 1 : false )
    {
        // Nothing to do
    }
    else
    {
        document.querySelector('.cookie-banner').classList.add('cookie-banner__visible');
    }
}
else
{
    // No Web Storage support
}

// localStorage.removeItem('cookie_accept'); // NB: if we're testing and need to remove the cookie
